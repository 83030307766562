:root {
 --btn-height-xs: 3.2rem;
 --btn-height-s: 3.8rem;
 --btn-height-m: 4.2rem;
 --btn-height-l: 4.4rem;

 --btn-font-xs: 1.4rem;
 --btn-font-s: 1.4rem;
 --btn-font-m: 1.6rem;
 --btn-font-l: 1.8rem;

 --btn-padding-xs: 0 1rem;
 --btn-padding-s: 0 1.2rem;
 --btn-padding-m: 0 1.4rem;
 --btn-padding-l: 0 1.8rem;

 --btn-padding-icon-xs: 0 1rem 0 1.4rem;
 --btn-padding-icon-s: 0 1.2rem 0 1.6rem;
 --btn-padding-icon-m: 0 1.4rem 0 1.8rem;
 --btn-padding-icon-l: 0 1.8rem 0 2.2rem;

 --btn-font-color-primary: white;
 --btn-font-color-error: white;
 --btn-font-color-mono: var(--mono-40);
 --btn-font-color-green: white;

 --btn-bg-primary: var(--primary);
 --btn-bg-error: var(--error);
 --btn-bg-mono: var(--mono-90);
 --btn-bg-green: var(--green);

 --btn-hover-primary: var(--primary-30);
 --btn-hover-error: var(--error-30);
 --btn-hover-mono: var(--mono-80);
 --btn-hover-green: var(--green-30);

 --btn-bg-active-primary: var(--primary-20);
 --btn-bg-active-error: var(--error-20);
 --btn-bg-active-mono: var(--mono-50);

 --btn-font-color-active-primary: white;
 --btn-font-color-active-error: white;
 --btn-font-color-active-mono: white;

 --btn-icon-size-xs: 1.6rem;
 --btn-icon-size-s: 1.8rem;
 --btn-icon-size-m: 1.8rem;
 --btn-icon-size-l: 2.4rem;

 --btn-icon-filter-primary: brightness(0) invert(1);
 --btn-icon-filter-error: brightness(0) invert(1);
 --btn-icon-filter-mono: invert(27%) sepia(9%) saturate(520%) hue-rotate(169deg) brightness(99%) contrast(91%);

 /*--btn-icon-filter-active-primary: invert(8%) sepia(94%) saturate(5010%) hue-rotate(214deg) brightness(90%) contrast(112%);*/
 /*--btn-icon-filter-active-error: invert(22%) sepia(14%) saturate(5396%) hue-rotate(317deg) brightness(78%) contrast(142%);*/
 /*--btn-icon-filter-active-mono: brightness(100%) invert(1);*/

 --btn-icon-filter-disabled: invert(90%) sepia(4%) saturate(236%) hue-rotate(179deg) brightness(95%) contrast(83%);

 --btn-outline-border-primary: var(--primary);
 --btn-outline-border-error: var(--error);
 --btn-outline-border-mono: var(--mono-40);

 --btn-outline-font-color-primary: var(--primary);
 --btn-outline-font-color-error: var(--error);
 --btn-outline-font-color-mono: var(--mono-40);

 --btn-outline-bg-hover-primary: var(--primary-op-08);
 --btn-outline-bg-hover-error: var(--error-op-08);
 --btn-outline-bg-hover-mono: rgba(70, 76, 82, 0.08);

 --btn-outline-border-active-primary: var(--primary-20);
 --btn-outline-border-active-error: var(--error-20);
 --btn-outline-border-active-mono: var(--mono-20);

 --btn-outline-bg-active-primary: rgba(24, 81, 221, 0.16);
 --btn-outline-bg-active-error: rgba(186, 26, 26, 0.16);
 --btn-outline-bg-active-mono: rgba(70, 76, 82, 0.16);

 --btn-outline-icon-filter-primary: var(--primary-filter);
 --btn-outline-icon-filter-error: var(--error-filter);
 --btn-outline-icon-filter-mono: invert(31%) sepia(6%) saturate(668%) hue-rotate(169deg) brightness(89%) contrast(92%);

 --btn-outline-icon-filter-active-primary: invert(10%) sepia(48%) saturate(6623%) hue-rotate(221deg) brightness(92%) contrast(104%);
 --btn-outline-icon-filter-active-error: invert(6%) sepia(89%) saturate(7119%) hue-rotate(354deg) brightness(66%) contrast(103%);
 --btn-outline-icon-filter-active-mono: brightness(0%) invert(30%);

 --headline-font-size-s: 2rem;
 --headline-font-size-m: 2.4rem;
 --headline-font-size-l: 2.8rem;

 --title-font-size-s: 1.6rem;
 --title-font-size-m: 1.8rem;
 --title-font-size-l: 2.2rem;

 --label-font-size-s: 1.4rem;
 --label-font-size-m: 1.6rem;
 --label-font-size-l: 1.8rem;

 --body-font-size-s: 1.4rem;
 --body-font-size-m: 1.6rem;
 --body-font-size-l: 1.8rem;

 --toggle-switch-height: 3.2rem;
 --toggle-switch-width: 7.2rem;
 --toggle-switch-indicator-size: 2.6rem;

 --tag-bg-primary: rgba(24, 81, 221, 0.08);
 --tag-bg-error: rgba(186, 26, 26, 0.08);
 --tag-bg-mono: rgba(70, 76, 82, 0.08);
 --tag-bg-green: rgba(30, 213, 94, 0.08);

 --tag-font-color-primary: var(--primary);
 --tag-font-color-error: var(--error);
 --tag-font-color-mono: var(--mono-40);
 --tag-font-color-green: var(--green);
}