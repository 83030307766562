@font-face {
    font-family: 'Pretendard';
    src: url("./fonts/Pretendard/Pretendard-Regular.otf") format('opentype');
    font-weight: 400;
    font-style: normal;
}

body {
    height: 100vh;
    width: 100%;
    margin: 0;
}

#root {
    height: 100%;
}


:root {
    --primary: #1851DD;
    --primary-op-05: rgba(24, 81, 221, 0.05);
    --primary-op-08: #EDF2FD;
    --primary-op-12: #E4EAFB;
    --primary-10: #0022AB;
    --primary-20: #0037BD;
    --primary-30: #003ab1;
    --primary-40: #1E54E0;
    --primary-50: #3E6CF7;
    --primary-60: #538DFF;
    --primary-70: #83ADFF;
    --primary-90: #DCE1FF;

    --secondary: var(--secondary-40);
    --secondary-10: #005A00;
    --secondary-20: #007800;
    --secondary-30: #008900;
    --secondary-40: #0A9A07;


    --green: #006E2B;
    --green-30: #00531E;
    --green-90: #EDFCF2;

    --warning-40: #FFBF00;

    --light: #CACDD2;
    --light-1: #F7F8F9;
    --light-2: #F0F2F3;
    --light-background: #FDFDFD;
    --light-background-1: #F7F8F9;
    --light-error: #DE3730;

    --background-1: #FFFFFF;
    --background-2: var(--mono-99);
    --background-3: var(--mono-95);
    --background-4: var(--mono-90);

    --error: #DE3730;
    --error-op-08: #FDEFEF;
    --error-op-12: #FBE7E6;

    --error-20: #690005;
    --error-30: #93000A;
    --error-60: #FF5449;
    --error-70: #FF897D;

    --mono: var(--mono-40);
    --mono-10: #1B1D1F;
    --mono-20: #323437;
    --mono-30: #494B4E;
    --mono-40: #606265;
    --mono-50: #76797E;
    --mono-60: #8E9095;
    --mono-70: #A5A7AC;
    --mono-80: #BDBFC4;
    --mono-90: #D4D6DB;
    --mono-95: #E5E7EC;
    --mono-99: #F7F8FA;

    --mono-op-08: #F3F3F3;

    --grey-40: #57605D;

    --placeholder: #A9AEB3;

    --bp-xl: 1200px;
    --bp-l: 992px;
    --bp-m: 768px;
    --bp-s: 576px;
    --bp-xs: 280px;

    --primary-filter: invert(26%) sepia(54%) saturate(6343%) hue-rotate(222deg) brightness(89%) contrast(94%);
    --error-filter: invert(28%) sepia(67%) saturate(5742%) hue-rotate(351deg) brightness(97%) contrast(79%);
    --error-70-filter: invert(61%) sepia(17%) saturate(1341%) hue-rotate(317deg) brightness(103%) contrast(101%);
    --placeholder-filter: invert(70%) sepia(6%) saturate(252%) hue-rotate(169deg) brightness(99%) contrast(87%);

    --mono-60-filter: invert(65%) sepia(2%) saturate(830%) hue-rotate(172deg) brightness(91%) contrast(93%);

    --side-nav-collapsed-width: 72px;
    --side-nav-expanded-width: 260px;
    --kanban-list-width: 380px;
    --modal-footer-height: 80px;

    --bs-body-line-height: normal;

    --gap-section: 3.2rem;
    --gap-title: 2.4rem;
    --gap-text-icon: 0.4rem;
    --gap-content: 2.4rem;
    --gap-button: 1.2rem;

    --padding-section-tbrl: var(--padding-section-top-bottom) var(--padding-section-left-right);
    --padding-section-top-bottom: 3.2rem;
    --padding-section-left-right: 2.4rem;
    --padding-default: 1.2rem;

    --padding-modal: var(--padding-modal-tb) var(--padding-modal-lr);
    --padding-modal-tb: 3.2rem;
    --padding-modal-lr: 4rem;

    --border-radius-default: 4px;
    --border-radius-large: 8px;

    --border-color: var(--mono-90);
    --border-color-varient: var(--mono-80);

    --content-color-secondary: var(--mono-40);
    --content-color-tertiary: var(--mono-60);
}

html, body {
    font-family: 'Pretendard', sans-serif !important;
    font-size: 62.5%;
}

* {
    overscroll-behavior: none;
}

dialog {
    border: none;
    outline: none;
    border-radius: 16px;
    padding: 0;
    opacity: 0;
}

dialog::backdrop {
    background-color: black;
    opacity: 0.3;
}

dialog[open] {
    opacity: 1;
    animation: modal-enter 0.3s forwards;
}

@keyframes modal-enter {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px white;
}

#naverIdLogin_loginButton {
    display: none;
}